import type { CModal, CLoadingButton } from '@coreui/vue-pro'
import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export interface IModalAction {
  id: string
  label: string
  cLoadingButtonProps: InstanceType<typeof CLoadingButton>['$props']
  callback: (props?: any) => void
}

export type ICModalProps = Omit<
  InstanceType<typeof CModal>['$props'],
  'visible'
>

export interface IModalOpen<TViewData = undefined> {
  viewValue: object
  CModalPropsValue: ICModalProps
  viewDataValue?: TViewData
  titleValue?: string
  actionsValue?: IModalAction[]
  modalQueryValue?: Record<string, string | string[]>
}

export const useModalStore = <TViewData = undefined>() =>
  defineStore('modal', () => {
    const route = useRoute()
    const isOpen = ref(false)
    const title = ref()
    const view = shallowRef<Record<any, any>>({})
    const viewData = ref<TViewData>()
    const CModalProps = shallowRef<ICModalProps>({})
    const actions = shallowRef<IModalAction[] | undefined>([])
    const modalQuery = ref()

    const hasModal = computed(() => Boolean(Object.keys(view.value).length))

    const handleModalOpenQuery = (
      newQuery: Record<string, string | string[]>
    ) => {
      const query = { ...route.query, ...newQuery }

      return navigateTo({
        name: String(route.name),
        params: { ...route.params },
        query,
      })
    }

    const open = ({
      viewValue,
      viewDataValue,
      actionsValue,
      titleValue,
      CModalPropsValue,
      modalQueryValue,
    }: IModalOpen<TViewData>) => {
      isOpen.value = true
      CModalProps.value = CModalPropsValue
      title.value = titleValue
      view.value = markRaw(viewValue)
      actions.value = actionsValue
      viewData.value = viewDataValue
      modalQuery.value = modalQueryValue

      if (modalQueryValue) {
        handleModalOpenQuery(modalQueryValue)
      }
    }

    const handleModalCloseQuery = () => {
      const query = Object.fromEntries(
        Object.entries(route.query).filter(([key]) => !modalQuery.value[key])
      )

      return navigateTo({
        name: String(route.name),
        params: { ...route.params },
        query,
      })
    }

    const close = () => {
      isOpen.value = false
      title.value = null
      view.value = {}
      viewData.value = undefined
      actions.value = []
      CModalProps.value = {}

      if (modalQuery.value) {
        handleModalCloseQuery()
        modalQuery.value = undefined
      }
    }

    const setActions = (actionsValue: IModalAction[]) => {
      actions.value = actionsValue
    }

    const changeViewData = (newViewData: TViewData) => {
      viewData.value = newViewData
    }

    return {
      viewData,
      isOpen,
      title,
      CModalProps,
      view,
      actions,
      modalQuery,
      hasModal,
      open,
      close,
      setActions,
      changeViewData,
    }
  })()
