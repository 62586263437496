import { translationsService } from '~/services/nuxt'
import { defineI18nConfig } from '#imports'

export const getLocaleByPriority = (): SUPPORTED_LANGUAGE_OPTIONS => {
  const userData = JSON.parse(localStorage.getItem('userData') || '{}')

  let userLanguage: SUPPORTED_LANGUAGE_OPTIONS =
    SUPPORTED_LANGUAGE_OPTIONS.English

  switch (userData.language) {
    case 1:
      userLanguage = SUPPORTED_LANGUAGE_OPTIONS.English
      break
    case 2:
      userLanguage = SUPPORTED_LANGUAGE_OPTIONS.Portuguese
      break
  }

  if (userLanguage) return userLanguage

  const navigatorLanguage = useBrowserLocale()
  const availableLanguages = Object.values(SUPPORTED_LANGUAGE_OPTIONS)
  const matchedNavigatorLanguage = availableLanguages.find(
    (language) => language === navigatorLanguage,
  )

  return matchedNavigatorLanguage || SUPPORTED_LANGUAGE_OPTIONS.English
}

export default defineI18nConfig(async () => {
  const localeByPrority = getLocaleByPriority()
  const messages = await translationsService.getMessages()

  return {
    legacy: false,
    locale: localeByPrority,
    fallbackLocale: localeByPrority,
    messages,
    silentTranslationWarn: process.env.NODE_ENV === 'production',
  }
})
