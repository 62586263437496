import { DateTime, type DateTimeFormatOptions } from 'luxon'
import type { ComposerTranslation } from 'vue-i18n'

// * Arquivo utilitário para formatação de datas (ver composable useDateUtilsAux)

/**
 * Retorna uma data no formato DateTime(Luxon)
 * @param {string | number | Date} date - A data a ser convertida.
 */
export const parseToDateTime = (date: string | number | Date) => {
  if (typeof date === 'number') {
    return DateTime.fromMillis(date)
  } else if (date instanceof Date) {
    return DateTime.fromJSDate(date)
  } else if (DateTime.fromISO(date).isValid) {
    return DateTime.fromISO(date)
  } else if (DateTime.fromRFC2822(date).isValid) {
    return DateTime.fromRFC2822(date)
  } else if (DateTime.fromHTTP(date).isValid) {
    return DateTime.fromHTTP(date)
  }
  throw new Error('Invalid date format')
}

/**
 * Recupera preferência de formato de data definido pelo usuário
 */
const getUserDateFormat = () => {
  const userData = JSON.parse(localStorage.getItem('userData') as string)
  return userData?.dateFormat || 'dd/MM/yyyy'
}

/**
 * Formata uma data no formato especificado (ex:'MMMM d yyyy')
 *
 * @param {string | number | Date} date - A data a ser formatada.
 * @param {string} [customFormat] - Opcional. O formato desejado para a data.
 * Se não for fornecido, usa o formato definido pelo usuário.
 * @param {string} [timeZone] - Opcional. Fuso horário para a conversão da data.
 *
 * @returns {string} - A data formatada como uma string.
 */
export const formatDate = (
  date: string | number | Date,
  customFormat?: string,
  timeZone?: string
) => {
  try {
    const dateTimeDate = parseToDateTime(date)

    const dateAux = useDateUtilsAux(dateTimeDate, timeZone)

    const format = customFormat || getUserDateFormat()

    return dateAux.toFormat(format)
  } catch (error) {
    throw new Error(`Error formatDate: ${error}`)
  }
}

/**
 * Formata uma data em algum formato pré-definido do Luxon
 *
 * @param {string | number | Date} date - A data a ser formatada.
 * @param {DateTimeFormatOptions} [format] O formato desejado para a data.
 * @param {string} [timeZone] - Opcional. Fuso horário para a conversão da data.
 *
 * @returns {string} - A data formatada como uma string.
 */
export const formatDateLocaleString = (
  date: string | number | Date,
  format: DateTimeFormatOptions,
  timeZone?: string
) => {
  try {
    const dateTimeDate = parseToDateTime(date)

    const dateAux = useDateUtilsAux(dateTimeDate, timeZone)

    return dateAux.toLocaleString(format)
  } catch (error) {
    throw new Error(`Error formatDateLocaleString: ${error}`)
  }
}

/**
 * Retorna quantidade de dias faltantes até a data esperada
 *
 * @param date
 * @param timeZone - Opcional. Fuso horário para o qual a data será convertida.
 *
 * @returns Um número com o resultado da diferePwhitença em dias entre as datas
 */
export const daysToDate = (date: string | number | Date, timeZone?: string) => {
  try {
    const start = useDateUtilsAux(DateTime.now(), timeZone)

    const dateTimeDate = parseToDateTime(date)
    const end = useDateUtilsAux(dateTimeDate, timeZone)

    const days = end.diff(start, 'days').toObject().days as number
    const roundedDays = Math.ceil(days)

    return Math.max(roundedDays, 0)
  } catch (error) {
    throw new Error(`Error daysToDate: ${error}`)
  }
}

/**
 * Retorna uma string traduzida indicando o tempo passado desde a data fornecida.
 *
 * @param date
 * @param $t - Função de tradução para obter textos no idioma atual.
 * @param timeZone - Opcional. Fuso horário para o qual a data será convertida.
 *
 * @returns Uma string como "2 anos atrás" ou "hoje".
 */
export const getTimeAgoTranslationByDate = (
  date: string | number | Date,
  $t: ComposerTranslation,
  timeZone?: string
): string => {
  try {
    const now = useDateUtilsAux(DateTime.now(), timeZone)
    const dateDateTime = parseToDateTime(date)
    const diff = Math.abs(now.diff(dateDateTime).as('days'))

    if (diff >= 365) {
      const years = Math.floor(diff / 365)
      const yearsLabel =
        years === 1 ? $t('general.dates.year') : $t('general.dates.year')

      return `${years} ${yearsLabel} ${$t('general.dates.go')}`
    } else if (diff >= 30) {
      const months = Math.floor(diff / 30)
      const monthsLabel =
        months === 1 ? $t('general.dates.month') : $t('general.dates.months')

      return `${months} ${monthsLabel} ${$t('general.dates.go')}`
    } else {
      const days = Math.floor(diff)
      const daysLabel =
        days === 1 ? $t('general.dates.day') : $t('general.dates.days')

      return days === 0
        ? $t('general.dates.today')
        : `${days} ${daysLabel} ${$t('general.dates.go')}`
    }
  } catch (error) {
    throw new Error(`Error getTimeAgoTranslationByDate: ${error}`)
  }
}
