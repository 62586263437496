<script setup lang="ts">
import { cilCheckAlt } from '@coreui/icons'
import { SUPPORTED_LANGUAGE_OPTIONS } from '~/stores/language'

const languageStore = useLanguageStore()
const setLanguage = (language: SUPPORTED_LANGUAGE_OPTIONS) => {
  languageStore.setUserLanguage(language)
}

interface IProps {
  isLayoutWithBgColor?: boolean
}

const props = defineProps<IProps>()

const featuresTranslationKeys = [
  'auth.newRegister.aside.text.import-contact',
  'auth.newRegister.aside.text.create-audience',
  'auth.newRegister.aside.text.send-personalized',
  'auth.newRegister.aside.text.create-beautiful',
  'auth.newRegister.aside.text.understand-audience',
]
</script>

<template>
  <div>
    <div class="tw-flex tw-flex-col tw-min-h-screen xl:tw-flex-row">
      <div
        class="tw-flex tw-flex-col tw-relative tw-px-5 tw-min-h-screen tw-pt-5 xl:tw-w-8/12 xl:tw-px-20 xl:tw-pt-20"
      >
        <NuxtImg
          src="img/logo-color.svg"
          width="168px"
          height="33px"
          class="tw-cursor-pointer"
          @click="() => navigateTo({ name: 'auth-login' })"
        />
        <slot />
        <ul
          class="tw-list-none tw-mt-auto tw-pl-0 tw-text-gray-500 tw-text-md tw-mb-4"
        >
          <li class="tw-inline-block tw-mr-2">
            <span> ©Copyright {{ new Date().getFullYear() }}. SendIt </span>
          </li>
          <li
            class="tw-inline-block tw-mr-2 tw-cursor-pointer"
            @click="setLanguage(SUPPORTED_LANGUAGE_OPTIONS.English)"
          >
            <span>{{ $t('general.footer.english') }}</span>
          </li>
          <li
            class="tw-inline-block tw-mr-20 tw-cursor-pointer"
            @click="setLanguage(SUPPORTED_LANGUAGE_OPTIONS.Portuguese)"
          >
            <span>{{ $t('general.footer.portuguese') }}</span>
          </li>
          <li class="tw-inline-block tw-mr-2">
            <!-- TODO: Adicionar link para página quando estiver pronta -->
            <NuxtLink
              class="tw-text-gray-500 hover:!tw-text-gray-700 tw-cursor-pointer"
            >
              {{ $t('general.footer.terms-conditions') }}
            </NuxtLink>
          </li>
          <li class="tw-inline-block">
            <!-- TODO: Adicionar link para página quando estiver pronta -->
            <NuxtLink
              class="tw-text-gray-500 hover:!tw-text-gray-700 tw-cursor-pointer"
            >
              {{ $t('general.footer.privacy-policy') }}
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div
        v-if="props.isLayoutWithBgColor"
        class="tw-w-full tw-bg-purple-600 tw-flex tw-items-center tw-justify-center tw-h-screen"
      >
        <div class="tw-text-white tw-p-4">
          <h1 class="tw-mb-7 tw-text-4xl tw-font-semibold">
            {{ $t('auth.newRegister.aside.header1.with-arpoone') }}
          </h1>
          <ul class="tw-p-0">
            <li
              v-for="translationKey in featuresTranslationKeys"
              :key="translationKey"
              class="d-flex align-items-center mb-4"
            >
              <CIcon
                class="tw-mr-3 !tw-text-primary"
                size="xxl"
                :icon="cilCheckAlt"
              />
              <p class="tw-mb-0 tw-text-xl tw-text-white">
                {{ $t(translationKey) }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-else
        class="tw-hidden tw-w-full tw-bg-[url(/img/bg-auth.png)] tw-bg-no-repeat tw-bg-cover xl:tw-block"
      />
    </div>
  </div>
</template>
