import payload_plugin_vuu8OH6TzG from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_pinia@2.3.1_rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_L9bc8L0tQg from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.31.0_sass@1.83.4_typescript@5.7.2_vite@6.0.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Sexte9PKz9 from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.31.0_sass@1.83.4_typescript@5.7.2_vite@6.0.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Gwp9WMzDsY from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.31.0_sass@1.83.4_typescript@5.7.2_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_b3GQKY8xcn from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.31.0_sass@1.83.4_typescript@5.7.2_vite@6.0.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iO8V7b2g9i from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.31.0_sass@1.83.4_typescript@5.7.2_vite@6.0.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jVJdcBHaQH from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.31.0_sass@1.83.4_typescript@5.7.2_vite@6.0.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_JHdtW6EQrF from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.31.0_sass@1.83.4_typescript@5.7.2_vite@6.0.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_I1x0ZBBiC7 from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_pinia@2.3.1_rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_lzYR2pcmZM from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.31.0_sass@1.83.4_typescript@5.7.2_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BmfaVgxlxA from "/app/node_modules/.pnpm/@nuxt+icon@1.10.3_rollup@4.31.0_vite@6.0.11_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_CygmuEFlf4 from "/app/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_@tanstack+vue-query@5.64.2_nuxt@3.15.2_rollup@4.31.0/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_LIsrdtAllY from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_eslint@9.18.0_rollup@4.31.0_typescript@5.7.2_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_de32gznu4v from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_eslint@9.18.0_rollup@4.31.0_typescript@5.7.2_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import errorCodeHandler_5jOIB6Wwtw from "/app/plugins/errorCodeHandler.ts";
import productFruits_f0XtXUbMc8 from "/app/plugins/productFruits.ts";
import stripe_6FZUdDHpIZ from "/app/plugins/stripe.ts";
export default [
  payload_plugin_vuu8OH6TzG,
  revive_payload_client_L9bc8L0tQg,
  unhead_Sexte9PKz9,
  router_Gwp9WMzDsY,
  payload_client_b3GQKY8xcn,
  navigation_repaint_client_iO8V7b2g9i,
  check_outdated_build_client_jVJdcBHaQH,
  chunk_reload_client_JHdtW6EQrF,
  plugin_vue3_I1x0ZBBiC7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lzYR2pcmZM,
  plugin_BmfaVgxlxA,
  plugin_CygmuEFlf4,
  switch_locale_path_ssr_LIsrdtAllY,
  i18n_de32gznu4v,
  errorCodeHandler_5jOIB6Wwtw,
  productFruits_f0XtXUbMc8,
  stripe_6FZUdDHpIZ
]