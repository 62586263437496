import UserControllerBase from '../base'
import type { OrganizationBalanceTypeEnum } from './types.js'
import { OrganizationStatusEnum } from './types.js'
import type {
  NGetOrganizations,
  NGetBusinessAreas,
  NGetUsers,
  NDesactivateUser,
  NActivateUser,
  NDeleteUser,
  NChangeRole,
  NGetUser,
  NGetOrganizationUsers,
  NGetSuborganizations,
} from './types'
import type { TResponseBase } from '~/services/MSAPI/types'

export default class UserControllerOrganization extends UserControllerBase {
  static readonly controllerPath = 'organization'
  private readonly serviceUrl: string

  constructor() {
    super(UserControllerOrganization.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async getOrganization(
    organizationId: string
  ): TResponseBase<NGetOrganizations.IOrganization> {
    return await useFetchInstance(`${this.serviceUrl}/${organizationId}`, {
      method: 'GET',
    })
  }

  async getOrganizations(
    params?: NGetOrganizations.IParams
  ): TResponseBase<NGetOrganizations.IResponseData> {
    return await useFetchInstance(`${this.serviceUrl}/organizations`, {
      method: 'GET',
      params,
    })
  }

  async getOrganizationUsers(
    organizationId: string,
    status: OrganizationStatusEnum = OrganizationStatusEnum.Active
  ): TResponseBase<NGetOrganizationUsers.IOrganizationUser[]> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/users`,
      {
        method: 'GET',
        params: { status },
      }
    )
  }

  async getBusinessAreas(
    params?: NGetBusinessAreas.IParams
  ): Promise<NGetBusinessAreas.IResponseData[]> {
    return await useFetchInstance(`${this.serviceUrl}/items/business-areas`, {
      method: 'GET',
      params,
    })
  }

  async updateOrganization(
    organizationId: string,
    data: NGetOrganizations.IOrganizationDetails
  ) {
    return await useFetchInstance(`${this.serviceUrl}/${organizationId}`, {
      method: 'PUT',
      body: data,
    })
  }

  async getUsers(
    organizationId: string,
    params?: NGetUsers.IParams
  ): TResponseBase<NGetUsers.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/userlist`,
      {
        method: 'GET',
        params,
      }
    )
  }

  async activateUser(
    organizationId: string,
    userId: string,
    params?: NDesactivateUser.IParams
  ): TResponseBase<NDesactivateUser.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/users/${userId}/enableorganizationuser`,
      {
        method: 'PATCH',
        params,
      }
    )
  }

  async desactivateUser(
    organizationId: string,
    userId: string,
    params?: NActivateUser.IParams
  ): TResponseBase<NActivateUser.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/users/${userId}/disableorganizationuser`,
      {
        method: 'PATCH',
        params,
      }
    )
  }

  async deleteUser(
    organizationId: string,
    userId: string,
    params?: NDeleteUser.IParams
  ): TResponseBase<NDeleteUser.TResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/user/${userId}`,
      {
        method: 'DELETE',
        params,
      }
    )
  }

  async changeRole(
    organizationId: string,
    userId: string,
    body: NChangeRole.IBody,
    params?: NChangeRole.IParams
  ): TResponseBase<NChangeRole.TResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/users/${userId}/changerole`,
      {
        method: 'PATCH',
        body,
        params,
      }
    )
  }

  async getUser(
    organizationId: string,
    userId: string,
    params?: NGetUser.IParams
  ): TResponseBase<NGetUser.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/users/${userId}`,
      {
        method: 'GET',
        params,
      }
    )
  }

  async getSuborganizations(
    organizationId: string,
    params: NGetSuborganizations.IRequest
  ): TResponseBase<NGetSuborganizations.IResponse> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/organizations`,
      {
        method: 'GET',
        params,
      }
    )
  }

  async getBalanceConfigType(
    organizationId: string
  ): TResponseBase<OrganizationBalanceTypeEnum[]> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/assignableorganizationbalanceconfigtypesforupdate`,
      {
        method: 'GET',
      }
    )
  }
}
